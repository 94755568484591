import React from "react";

const AboutCareer = () => {
  return (
    <div data-aos="fade-in" data-aos-duration={900}>
      <div data-aos="fade-up" data-aos-duration={600}>
        <div className="row justify-content-center">
          <div className="text-xl-justify pr-lg-10 pr-xl-0 col-lg-12 col-sm-12 col-md-12 col-xs-12">
            <h3 className="font-size-8 mb-0 mt-10 m-4">
              Cloudstack, a dynamic and rapidly growing technology company, is
              on a mission to revolutionise the business efficiency and sales
              landscape. Our vision extends beyond products; it's about
              fostering a culture of innovation, collaboration, and excellence.
              We are seeking passionate and forward-thinking Software Engineers
              to join our journey and help innovate the future.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCareer;
